.form-control {
	box-shadow: none !important;
	color: $black!important;
	height: 40px;
	font-weight: 300;
	border: 0;

	&.with-border { 
		border: 1px solid color.adjust(map.get($colors, 'black'), $lightness: 60%) !important;
		color: map.get($colors, 'black') !important;
		min-height:  40px;
		height: auto;

		&.type-selectpicker {
			padding: 0 !important;			
		}

	}

  &::placeholder {
    color: lighten($black, 70%);
  }
}

textarea {
	&.form-control {
		height: auto;
	}
}


.select2-container {
	text-align: left;

	&.select2-container--default {
		.select2-selection--single {
			border-radius:  10px !important;
			height: 40px;
			border: 0;

			.select2-selection {
				&__placeholder {
					opacity: 1;
					color: lighten($black, 70%);
				}
				
				&__rendered {
					line-height: 3em;
					color: map.get($colors, 'dark');
					padding-left: 14px;
    			padding-right: 25px;
				padding-left: 20px;
				}
				
				&__arrow {
					top: 8px;
					right: 5px;
					b {
						border-top-color: map.get($colors, 'black');
					}
				}
			}
			
		}
	}

	.select2-results__option--highlighted {
		&.select2-results__option--highlighted {
			background-color: darken(map.get($colors, 'green'), 2%);
		}
	}
}

.select2-dropdown {
	border: 0;
}
.select2-results__option {
	background-color: map.get($colors, 'grey');
	padding:  6px 15px;
}

.file-picker {
  display: inline-block;
	height: calc(2.25rem + 2px);
	margin-bottom: 0;
	position: relative;
	width: 100%;

	&--input {
    height: calc(2.25rem + 2px);
		margin: 0;
		opacity: 0;
		position: relative;
		width: 100%;
		z-index: 2;
	}

	&--label {
    height: calc(2.25rem + 2px);
    left: 0;
		position: absolute;
    top: 0;
    right: 0;
		z-index: 1;
		@extend .form-control;

		&::after {
			align-items: center;
			background-color: $black;
			bottom: 0;
			color: $white;
			display: flex;
			justify-content: center;
			height: calc(calc(2.25rem + 2px) - 1px * 2);
			padding: 0 15px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 3;
		}
	}

	&--input:lang(fr) ~ .file-picker--label {
    &::after {
      content: "Rechercher";
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
	border: none !important;
	-webkit-text-fill-color: inherit !important;
	-webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
	transition: background-color 5000s ease-in-out 0s;
}

.ui-button.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-state-default.ui-state-active.ui-state-hover {
	border: none;
	background: map.get($colors, 'black') !important;
}