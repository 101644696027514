
#footer {
	background-color: map.get($colors,'black');
	color: $white;

	.icon-facebook {
		position: relative;
		margin-top: -3px;
	}

	.link {
		display: block;
		transition: all .2s ease-in-out;
		margin-bottom: 2px;
		@include font-size(12);

		&.with-icon {
			display: block;
		}

		&:hover {
			transform: translateX(5px);
			color: map.get($colors, 'white');
		}
	}

	.sprite {
		display: block;
		transition: all .1s ease-in-out;

		&:hover {
			transform: translateY(3px);
		}
	}

	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {}

	@include media-breakpoint-down(sm) {}

	.footer-pixell {
		border-top: 1px solid map.get($colors, 'white');

	}	
}