.blog {
  &--navigation {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid lighten(map.get($colors, 'black'), 72%);
    padding: 20px 30px;

    &--list {
      &--item {
        &:not(:first-child) {
          margin-top: 5px;
        }
        &--link {
          color: lighten(map.get($colors, 'black'), 15%);
          display: block;
          font-family: $font-regular;
          padding-left: 20px;
          position: relative;
          transition: all 0.1s ease-in-out;
          @include font-size(16);
    
          &:before {
            background-color: map.get($colors, 'blue');
            content: "";
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            top: 12px;
            transition: all 0.2s ease-in-out;
            width: 8px;
          }
        
          &.active {
            color: map.get($colors, 'blue') !important;
            font-family: $font-regular;
            &:before {
              border-color: map.get($colors, 'blue') !important;
              background-color: map.get($colors, 'blue') !important;
            }
          }
        
          &:hover {
            color: map.get($colors, 'blue');
            padding-left: 25px;

            &:before {
              background-color: map.get($colors, 'blue');
            }
          }
        }
      }
    }
  
    @include media-breakpoint-down(sm) {
      border: none;
      box-shadow: 0 0 20px rgba(0,0,0,0.1);
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &--article {
    background-color: $white;
    border-radius: 4px;
    display: block;
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 0;
    transition: all 0.2s ease-in-out;

    &.with-grey {
      background-color: map.get($colors, 'grey');
      padding-bottom: 30px;
    }

    &--button {
      bottom: 15px;
      position: absolute;
      right: 30px;
      color: map.get($colors, 'blue')
    }

    &--thumbnail {
      position: relative;
      overflow: hidden;

      &:after {
        background-color: rgba(0,0,0, 0.2);
        content: "";
        left: 0;
        height: 100%;
        position: absolute;
        top: 0;
        transition: all 0.1s ease-in-out;
        width: 100%;
        z-index: 1;
      }
  
      .img-fluid {
        transition: all 0.1s ease-in-out;
      }

      &--category {
        border-radius: 0 4px 0 0;
        bottom: 0;
        background-color:map.get($colors, 'blue');
        color: $white;
        font-family: $font-regular;
        left: 0;
        padding: 5px 15px;
        position: absolute;
        text-transform: uppercase;
        z-index: 2;
        @include font-size(11);
      }
    } 

    &:hover {
      color: lighten(map.get($colors, 'black'), 30%);
      .blog--article--thumbnail {
        &:after {
          opacity: 0;
        }
        
        .img-fluid {
          transform: scale(1.1);
        }
      }
    }    
  }
}
