@use "sass:color";
@use "sass:math";
@use "sass:map";

@import '~bootstrap/scss/functions';
@import 'bootstrap/variables-bootstrap';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import 'bootstrap/variables-bootstrap-utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/reboot';
//@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/popover';
//@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/tables';
//@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/transitions';

@import 'mixins/spacing-generator';
@import 'mixins/font-size';
@import 'mixins/triangle';
@import 'mixins/transition';

@import 'helpers/colors';
@import 'helpers/fonts';
@import 'helpers/buttons';
@import 'helpers/form';
@import 'helpers/print';
@import 'helpers/sprite';
@import 'helpers/spacing';

@import 'layout/reboot';
@import 'layout/header';
@import 'layout/header-reservation';
@import 'layout/footer';

@import 'components/aos';
@import 'components/google-map';
//@import 'components/jquery.parallax';
//@import 'components/ion-range-slider';
@import 'components/jquery-ui';
//@import 'components/magnific-popup';
@import 'components/parsleyjs';
@import 'components/slick';
@import 'components/select2';

@import 'pages/commons';
@import 'pages/reservation-step-commons';
@import 'pages/reservation-form';
@import 'pages/reservation-step-1';
@import 'pages/reservation-step-2';
@import 'pages/reservation-step-3';
@import 'pages/reservation-confirmation';
@import 'pages/reservation-login';
@import 'pages/blog';
@import 'pages/home';
@import 'pages/contact';
@import 'pages/wysiwyg';


