.reservation-step-2 {
  &--item {
    background-color: map.get($colors, 'grey');
    border-bottom: 1px solid lighten(map.get($colors, 'black'), 70%);
    border-radius: 10px;
    color: map.get($colors, 'black');
    overflow: hidden;
    position: relative;
    width: 100%;
    @include font-size(14);

    &.higlighted {
      background-color: map.get($colors, 'blue');
      border: 0;
      
      label {
        color: map.get($colors, 'white') !important;
        font-family: $font-regular !important;
        
        em { 
          color: map.get($colors, 'white') !important;          
        }
        
        .material-icons {
          color: map.get($colors, 'green');
          vertical-align: middle;
          @include font-size(18);
        }
      }

      .more-info-icon {
        color: map.get($colors, 'white') !important;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .more-info-icon {
      color: lighten(map.get($colors, 'black'), 20%);
      margin-top: -10px;
      position: absolute;
      right: 10px;
      top: 50%;
      z-index: 2;

      .material-icons {
        @include font-size(18);
      }
    }

    .content {
      padding: 15px 10px;
    }

    .checkboxradio-wrapper {
      align-items: center;


      > label,
      > input {
        cursor: pointer;
      }

       > label {
        &.except {
          display: flex;
          align-items: center;
        }
      }

      > input {
        opacity: 0;
        position: absolute;

        &[type=checkbox] {
          & + label {
            background-image: url('assets/icons/sprite-checkbox.png');
          }
        }

        &[type=radio] {
          & + label {
            background-image: url('assets/icons/sprite-radio.png');
          }
        }

        &[type=checkbox],
        &[type=radio] {
            & + label {
            background-size: 56px;
            background-position: 0 bottom;
            background-repeat: no-repeat;
            color: lighten(map.get($colors, 'black'), 50%);
            font-weight: 500;
            height: 56px;
            font-family: $font-regular;
            line-height: 1.15em;
            margin-bottom: 0;
            padding: 0 60px 0 70px;
            width: 100%;
            @include font-size(16);

            &.except {
              font-family: $font-regular;
              color: lighten(map.get($colors, 'black'), 20%);
            }

            em {
              color: lighten(map.get($colors, 'black'), 50%);
              display: block;
              font-weight: 300;
              font-size: 85%;
              margin-top: 3px;
            }

            @include media-breakpoint-down(sm) {
              @include font-size(15);
            }
          }

          &:checked {
            & + label {
              background-position: 0 top;
              color: map.get($colors, 'blue');
              font-family: $font-regular;
            }
          }
        }
      }
    }

    .quantite, .choix {
      margin-top: -10px;
      padding: 0 0 0 70px;

      .selectpicker-wrapper {
        display: inline-block;
        position: relative;
      }
      .label {
        color: map.get($colors, 'black');
        font-weight: normal;
        vertical-align: middle;
        padding-right: 10px;
      }
      .select2-container {
        &.select2-container--default {
          .select2-selection--single {
            height: auto;
            width: 40px;

            .select2-selection {
              &__rendered {
                line-height: 1.8em;
                padding-left: 7px;
                padding-right: 20px;
              }

              &__arrow {
                top: 0;
                right: 0;
              }
            }
          }
        }
      }

      &.disabled .select2,
      &.disabled .label {
        opacity: 0.4;
      }
    }
  }
}
