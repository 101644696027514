$buttons: (
  (
    button-regular,
    inline-block,
    14,
    700,
    uppercase,
    1.2,
    12px 20px 11px,
    center,
    5px,
    map.get($colors,'blue'),
    transparent,
    3px solid map.get($colors,'blue'),
    $white, 
    map.get($colors,'blue'),
    3px solid map.get($colors,'blue')
  ),
  (
    button-regular-alt,
    inline-block,
    14,
    700,
    uppercase,
    1.2,
    12px 20px 11px,
    center,
    5px,
    $white,
    transparent,
    3px solid $white,
    map.get($colors,'blue'),
    $white, 
    3px solid $white
  ),
  (
    button-submit,
    inline-block,
    14,
    300,
    uppercase,
    1.2,
    9px 20px 8px,
    center,
    5px,
    $white,
    map.get($colors,'green'),
    2px solid map.get($colors,'green'),
    $white,
    map.get($colors,'green'),
    2px solid map.get($colors,'green'),
  ),    (
    button-submit-alt,
    inline-block,
    14,
    300,
    uppercase,
    1.2,
    9px 20px 8px,
    center,
    5px,
    map.get($colors,'green'), 
    $white,
    2px solid  map.get($colors,'green'), 
    map.get($colors,'green'), 
    $white,
    2px solid  map.get($colors,'green'), 
  ),  
);

@each $label,
  $display,
  $font-size,
  $font-weight,
  $text-transform,
  $line-height,
  $padding,
  $text-align,
  $border-radius,
  $color,
  $background-color,
  $border,
  $hover-color,
  $hover-background,
  $hover-border in $buttons {
  .#{$label} {
    background-color: $background-color;
    border: $border;
    border-radius: $border-radius;
    color: $color;
    display: $display;
    font-weight: $font-weight;
    line-height: $line-height;
    padding: $padding;
    text-align: $text-align;
    text-transform: $text-transform;
    transition: all 0.2s ease-in-out;
    @include font-size($font-size);

    &:hover,
    &:focus {
      background-color: $hover-background;
      color: $hover-color;
      border: $hover-border;
    }

    &.dropdown-toggle {
      background-color: darken(map.get($colors, 'grey'), 5%);
      color: map.get($colors, 'black');
      border-color: darken(map.get($colors, 'grey'), 5%);
      &:after {
        margin-left: 1.255em;
      }
    }

    > * {
      vertical-align: middle !important;
    }

    @if $label == button-regular or $label == button-regular-alt  {
      min-height: 40px;
    }

    @if $label == button-submit or $label == button-submit-alt {
      &.large {
        padding: 12px 23px 11px;
        font-weight: 600;
        @include font-size(17);

        &:hover {
          transform: scale(.98);
          box-shadow: 0 0 15px 2px rgba(0,0,0,.2);
        }

        @include media-breakpoint-down(md) {
          display: block;
          width: 100%;
        }
      }
    }

    @if $label == button-regular {
      &:hover {
        .arrow-right-blue-small {
          background-position: -400px 0;
        }
      }    
    }

    @if $label == button-regular-alt {
      &:hover {
        .arrow-right-white {
          background-position: -390px 0;
        }
      }    
    }

    @if $label == button-regular-alt {
      position: relative;
    }
  }
}

.button {
  &--mobile {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    height: 30px;
    position: relative;
    transform: rotate(0deg);
    width: 40px;
    z-index: 12;
    @include transition(all, 0.2s, ease-in-out);
  
    span {
      background: map.get($colors, 'black');
      display: block;
      height: 5px;
      border-radius: 4px;
      left: 0;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      width: 100%;
      @include transition(all, 0.2s, ease-in-out);
    }
  
    span:nth-child(1) {
      top: 0;
    }
  
    span:nth-child(2),
    span:nth-child(3) {
      top: 12px;
    }
  
    span:nth-child(4) {
      top: 24px;
    }  
  }

  &--scroll-top {
    bottom: 30px;
    position: fixed;
    right: 30px;
    z-index: 99;
  
    @include media-breakpoint-down(sm) {
      bottom: 15px;
      right: 15px;
    }
    
    &:hover,
    &:focus {}  
  }
}

.menu-open {
  .button--mobile {
    span:nth-child(1) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  
    span:nth-child(2) {
      transform: rotate(45deg);
    }
  
    span:nth-child(3) {
      transform: rotate(-45deg);
    }
  
    span:nth-child(4) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  }
}