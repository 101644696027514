.reservation-step-3 {
  &--input {
    background-color: map.get($colors, 'grey');
    border-bottom: 1px solid lighten(map.get($colors, 'black'), 70%);
    border-radius: 10px;
    padding: 10px 15px;
  
    label {
      color: lighten(map.get($colors, 'black'), 15%);
      font-family: $font-regular;
      text-transform: uppercase;
      @include font-size(11);
    }
    .ui-datepicker-trigger {
      margin-top: -14px;
    }
  
    input,
    textarea,
    .selectpicker-wrapper {
      border: none;
      border-radius: 4px;
      font-weight: 300;
      box-shadow: 0 0 5px rgba(0, 0, 0, .05);
      margin-top: 5px;
      outline: none !important;
  
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}


.estimate-form {
  background-color: #fff;
  border: 2px solid map.get($colors, 'grey');
}