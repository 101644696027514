.review-wrapper {
  position: absolute;
  top: -120px;
  right: -60px;
  color: $white;
  text-align: center;

  .text-large {
    font-weight: 600;
    line-height: 1.2em;
    @include font-size(50);
  }
  .text-small {
    font-weight: 500;
    line-height: 1.2em;
    @include font-size(18)
  }

  @include media-breakpoint-down(xxl) {
    right: -30px;
  } 
  @include media-breakpoint-down(lg) {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 20px;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
 
}

.about-wrapper {
  position: relative;
  
  &--image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(50% - 50px);

    
  }  
  @include media-breakpoint-down(lg) {
    padding-top: 400px;
    &--image {
      height: 400px;
      width: 100%;
    }
  }
}

.accordion-item {
  border-radius: 5px;
  background-color: map.get($colors, 'grey');
  margin-bottom: 15px;

  &--button {
    display: block;
    padding: 10px 40px 15px 20px;
    position: relative;
    text-align: left;
    width: 100%;
  
    .sprite {
      position: absolute;
      top: 50%;
      margin-top: -5px;
      right: 15px;
    }
  }

  &--body {
    padding: 15px 20px 15px 20px;
    font-weight: 300;
    border-top: 1px solid lighten(map.get($colors, 'black'), 70%);
    box-shadow: 0 2px 5px -2px inset rgba(0,0,0,.1);
    @include font-size(15);
  }
  
}


.reservation-car-item {
  overflow: hidden;

  .img-wrapper {
    display: block;
    padding: 0 15px;
    
    img {
      transition: all .1s ease-in-out;
      width: 100%;
    }
  }

  .text-wrapper {
    display: block;
    margin-top: 15px;
    text-align: center;
    transition: all .1s ease-in-out;
  }

  .link {
    display: block;
    width: 100%;

    .footer-wrapper {
      bottom: 0;
      position: absolute;
      padding: 0 30px 30px;
      width: 100%;
    }
  
    &:hover {
      img {
        transform: scale(.95);
        opacity: .8;
      }
      .text-wrapper {
        transform: translateY(-10px);
      }
    }
  }

}
