.reservation-step-1 {

  &--filter-wrapper {
    @include media-breakpoint-up(sm) {
      background-color: $white;
      position: relative;
      width: calc(100% + 4px);
      z-index: 10;
    }

    &--bar {
      background-color: map.get($colors, 'blue');
      border-radius: 5px;
      margin-top: 15px;
      padding: 20px 80px 20px 20px;
      position: relative;

      @include media-breakpoint-down(md) {
        padding-right: 85px;
      }

      &--submit {
        align-items: center;
        background-color: map.get($colors, 'green');
        border-radius: 5px;
        color: $white;
        display: flex;
        height: 40px;
        justify-content: center;
        padding-top: 5px;
        position: absolute;
        right: 20px;
        top: 20px;
        width: 40px;

        .material-icons {
          @include font-size(28);
        }

        @include media-breakpoint-down(md) {
          bottom: 20px;
          height: 150px;
          top: auto;
          width: 50px;
        }

      }
    }
  }

  &--item {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05);
    border: 1px solid lighten(map.get($colors, 'black'), 70%);
    display: flex;
    /*overflow: hidden;*/
    position: relative;
    transition: all .2s ease-in-out;
    width: 100%;

    .promo {
      position: absolute;
      top: -25px;
      right: -25px;
      width: 60px;
      height: 60px;
      background-color: map.get($colors, 'blue');
      border-radius: 60px;
      text-transform: uppercase;
      z-index: 4;
      font-size: px(10);
      color: $white;
      text-align: center;
      font-weight: 600;
      display: block;
      padding-top: 24px;
      line-height: 1em;
      transform: rotate(23deg) scale(1.2);
    }

    &:hover {
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
    }

    .column {
      &.img-wrapper {
        align-items: center;
        border-right: 1px solid lighten(map.get($colors, 'black'), 70%);
        display: flex;
        justify-content: center;
        position: relative;
        padding: 30px;
        width: 325px;

        .galery-more {
          background-color: map.get($colors, 'grey');
          bottom: 0;
          color: map.get($colors, 'black');
          height: 40px;
          line-height: 1.1em;
          padding-top: 7px;
          position: absolute;
          transition: all .1s ease-in-out;
          text-align: center;
          right: 0;
          width: 40px;
          z-index: 2;
          @include font-size(24);

          &:hover {
            background-color: lighten(map.get($colors, 'black'), 77%);
          }
        }
      }

      &.content-wrapper {
        padding: 20px 30px;
        width: 650px;

       .title {
          color: map.get($colors, 'blue');
          font-family: $font-regular;
          margin-bottom: 10px;
          @include font-size(24);

          .similar {
            color: lighten(map.get($colors, 'black'), 30%);
            font-family: $font-regular;
            font-style: italic;
            @include font-size(12);
          }
        }

        .category{
          display: inline-block;
          border-radius: 15px;
          color: $black;
          line-height: 1em;
          padding: 5px 10px;
          @include font-size(12);

          &:not(.alternative) {
            background-color: map.get($colors, 'grey');
          }
        }


        .description {
          line-height: 1.2em;
          margin-top: 20px;
        }

        .options-wrapper {
          margin-top: 15px;

          .item {
            float: left;
            margin-bottom: 5px;
            /*padding-left: 25px;*/
            position: relative;
            width: 40%;
          }

          .item-row {
            padding-left: 25px;
            width: 100%;
          }

          .sprite,
          .txt {
            vertical-align: middle;
          }

          .txt {
            @include font-size(12);
          }

          .material-icons {
            color: map.get($colors, "blue");
            left: 0;
            margin-right: 5px;
            position: absolute;
            top: 4px;
            @include font-size(18);
          }
        }
      }

      &.price-wrapper {
        border-radius: 0;
        background-color:lighten(map.get($colors, 'grey'), 3%);
        border-left: 1px solid lighten(map.get($colors, 'black'), 70%);
        padding: 0;
        text-align: center;
        width: 350px;

        .single-col {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          flex-flow: column nowrap;
          padding: 30px 20px;
          width: 100%;

          .unavailable-date,
          .unavailable-txt {
            color: lighten(map.get($colors, 'black'), 40%);
            line-height: 1.2em;
            text-align: center;
            @include font-size(14);
          }

          .unavailable-date {
            color: lighten(map.get($colors, 'black'), 20%);
            font-family: $font-regular;
            margin-top: 10px;
            @include font-size(17);
          }
        }

        .bloc {
          align-items: flex-start;
          display: block;
          height: 50%;
          justify-content: center;
          flex-flow: column wrap;
          padding: 20px 30px 15px;
          width: 100%;


          &.left {
            border-bottom: 1px solid lighten(map.get($colors, 'black'), 70%);
          }

          &.right {
            .total {
              color: lighten(map.get($colors, 'black'), 30%);
            }
          }
          &.left {
            .total {
              font-weight: 700 !important;
            }
          }
        }

        .button {
          background-color: lighten(map.get($colors, 'black'), 70%);
          border-radius: 30px;
          color: lighten(map.get($colors, 'black'), 30%);
          display: block;
          line-height: 1.2em;
          font-weight: 700;
          margin: 0 auto;
          text-transform: uppercase;
          padding: 12.5px 30px;
          width: 100%;
          transition: all 0.05s ease-in-out;
          @include font-size(12);


          &:hover {
            background-color: lighten(map.get($colors, 'black'), 73%);
            transform: scale(.95);
          }

          &.active {
            background-color: map.get($colors, 'green');
            color: $white;

            &:hover {
              background-color: lighten(map.get($colors, 'green'), 2%);
            }
          }

          .txt {
            font-family: $font-regular;
            text-transform: uppercase;
          }

          small {
            font-weight: 400;
            display: block;
          }
        }

        .total {
          color: map.get($colors, 'green');
          font-weight: 400;
          line-height: 1em;
          /*margin-bottom: 15px;*/
          position: relative;
          text-transform: uppercase;
          @include font-size(22);

          small {
            font-size: 60%;
          }
        }

        .total-day {
          margin-bottom: 10px;
          @include font-size(14);
        }
      }
    }

    &.unavailable {
      &:after {
        content: '';
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: calc(100% - 350px);
        z-index: 3;
      }
    }

    &.display-only {
      .button.active {
        padding-bottom: 20px !important;
        padding-top: 20px !important;
        width: 80% !important;
      }
    }
  }

  @include media-breakpoint-down(xxl) {
    &--item {
      &.unavailable {
        &:after {
          width: calc(100% - 300px);
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    &--item {
      .column {
        &.content-wrapper {
          width: 450px;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &--item {
      padding-bottom: 130px;

      .column {
        &.img-wrapper {
          width: 250px;
        }

        &.price-wrapper {
          background-color: map.get($colors, 'grey');
          border-top: 1px solid darken(map.get($colors, 'grey'), 10%);
          border-left: none;
          bottom: 0;
          display: flex;
          flex-flow: row nowrap;
          height: auto !important;
          left: 0;
          padding: 0;
          position: absolute;
          width: 100%;

          .bloc {
            height: 130px;
            width: 100%;
            &.left {
              border-bottom: 0;
              border-left: 1px solid darken(map.get($colors, 'grey'), 10%);
              order: 1;
            }
          }

        }
      }

      &.unavailable {
        padding-bottom: 103px;

        .column {
          .price-wrapper {
            .bloc {
              .left {
                justify-content: center;
              }
            }
          }
          .single-col {
            .unavailable-date {
              margin-top: 5px;
            }
          }
        }

        &:after {
          width: 100%;
          height: calc(100% - 103px);
        }
      }

      &.display-only {
        padding-bottom: 107px;
        .button.active {
          padding-bottom: 15px !important;
          padding-top: 15px !important;
          width: 50% !important;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &--item {
      display: block;
      padding-bottom: 0 !important;

      .column {
        border-right: none !important;
        display: block;
        padding-left: 20px !important;
        padding-right: 20px !important;
        width: 100% !important;

        &.img-wrapper {
          display: block;
          padding-bottom: 10px;
          padding-top: 10px;
          text-align: center;
          width: 100%;
        }

        &.content-wrapper {
          border-top: 1px solid lighten(map.get($colors, 'black'), 70%);
          border-bottom: 1px solid lighten(map.get($colors, 'black'), 70%);
        }

        &.price-wrapper {
          position: relative;
          bottom: auto;
          left: auto;
          padding: 0 !important;
          border-top: none !important;

          .bloc {
            height: auto;
            padding: 20px;
          }
        }

      }
      &.unavailable {
        padding-bottom: 0;
        &:after {
          height: calc(100% - 103px);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &--item {
      display: block;
      padding-bottom: 0;

      .column {
        &.price-wrapper {
          width: 100%;
          flex-flow: column wrap;

          .bloc {
            padding: 15px 20px;
            height: auto;
            width: 100%;

            .button {
              width: 250px;
            }

            &.left {
              order: 0;
              border-bottom: 1px solid lighten(map.get($colors, 'black'), 70%);
              border-left: none;
            }
          }
        }
      }
    }
  }
}
