.datepicker-wp {
	position: relative;

	.form-control {
		padding-right: 30px;
	}

	.ui-datepicker-trigger {
		margin-top: -12px;
		position: absolute;
		right: 8px;
		top: 50%;

		.material-icons {
      @include font-size(18)
		}
	}
}

.ui-datepicker {
  background-image: none !important;
	border-radius: 0 !important;
  @include font-size(14);
	z-index: 100 !important;


	.ui-datepicker-title {
		text-transform: uppercase;
	}

	.ui-widget-header {
		background: lighten($black, 90%) !important;
		border-radius: 0 !important;
		border: none !important;
	}

	.ui-button,
	.ui-state-default,
	.ui-widget-content .ui-state-default,
	.ui-widget-header .ui-state-default {
		border: none;
		background: lighten($black,95%);
	}

  .ui-datepicker-today .ui-state-highlight {
    border: none;
    background: map.get($colors, 'blue');
    color: $white;
  }

  .ui-datepicker-today .ui-state-highlight:hover {
    color: $black;
  }

  .highlighted-start-end a {
    background-color: map.get($colors, 'green')!important; /* Couleur de fond pour la date de début et de fin */
    color: white!important;
  }

  .highlighted-between a {
    background-color: map.get($colors, 'green-light') !important; /* Couleur de fond pour les dates entre le début et la fin */
    color: white!important;
  }

	.ui-button.ui-state-active:hover,
	.ui-button:active,
	.ui-state-active,
	.ui-widget-content .ui-state-active,
	.ui-widget-header .ui-state-active,
	a.ui-button:active {
		border: none;
		background: map.get($colors, 'blue') !important;
	}

	.ui-button:focus,
	.ui-button:hover,
	.ui-state-focus,
	.ui-state-hover,
	.ui-widget-content .ui-state-focus,
	.ui-widget-content .ui-state-hover,
	.ui-widget-header .ui-state-focus,
	.ui-widget-header .ui-state-hover {
		background: transparent !important;
	}
}
