$colors: (
  'black': #14181C,
  'white': #FFFFFF,
  'grey': #EEEEEE,
  'grey-light': #f2f2f2,
  'error': #a94442,
  'green': #008100,
  'green-light': #0081007a,
  'blue': #2020FE
);

@each $name,
$value in $colors {
  .color-#{$name} {
    color: $value;
  }

  .background-#{$name} {
    background-color: $value;
  }

}
