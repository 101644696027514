.item-contact {
  .title-contact {
    position: relative;
    padding-left: 25px;
    font-family: $font-regular;
    text-transform: uppercase;
    @include font-size(16);
  }

  .title-contact .material-icons {
    position: absolute;
    color: map.get($colors, 'blue');
    top: 1px;
    left: 0;
    @include font-size(18);
  }

  .list {
    margin-top: 12px;
    padding-left: 8px;
  }

  .list li {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 12px;
    color: map.get($colors, 'black');
    line-height: 1.2em;
    word-break: break-word;
    font-family: $font-regular;
    @include font-size(14);
    
    .material-icons {
      position: absolute;
      color: map.get($colors, 'blue');
      top: 1px;
      left: -10px;
      width: 4px;
      height: 1px;  
      transition: all .2s ease-in-out;
      @include font-size(18);
    }

    a {
      display: block;

      &:hover {
        color: map.get($colors, 'blue');
        .material-icons {
          transform: translateX(-5px);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }
}
