@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/MaterialIcons-Regular.woff') format('woff');
}

@font-face {
  font-display: swap; 
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/lexend-deca-v21-latin-300.woff2') format('woff2');
}

@font-face {
  font-display: swap; 
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/lexend-deca-v21-latin-500.woff2') format('woff2');
}

@font-face {
  font-display: swap; 
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/lexend-deca-v21-latin-600.woff2') format('woff2');
}

$font-regular: 'Lexend Deca', sans-serif;

$fonts: (
  (title-1, 58, 700, normal, 1.1, title),
  (title-2, 38, 500, normal, 1.2, title),
  (title-3, 38, 300, normal, 1.2, title),
  (title-4, 20, 600, normal, 1.2, title),
  (title-5, 20, 500, normal, 1.4, title),
  (title-6, 18, 300, normal, 1.1, title),
  (title-7, 30, 600, normal, 1.4, title),
  (text-1, 20, 300, normal, 1.4, text),
  (text-2, 18, 300, normal, 1.4, text),
  (text-3, 16, 600, normal, 1.4, text),
  (text-4, 14, 600, normal, 1.4, text),
  (text-5, 14, 300, normal, 1.4, text),
);

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; 
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
 }


html {
  color: $black;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-regular;
}

.text-justify {
  text-align: justify;
}

@each $label,
$font-size,
$font-weight,
$font-style,
$line-height,
$type in $fonts {
  .#{$label} {
    line-height: $line-height;
    font-style: $font-style;
    font-weight: $font-weight;
    @include font-size($font-size);

    mark {
      background-color: map.get($colors, "blue");
      color: $white;
      padding: 0 10px;
      display: inline;
    }

    @if $type == title {
      @include media-breakpoint-down(md) {
        @include font-size(calc(math.div($font-size, 1.1)));
      }
    }

    @include media-breakpoint-down(sm) {
      @if $type == text and $font-size > 15 {
        @include font-size(calc(math.div($font-size,1.15)));
      } 
    }

    @if $label == title-1 {
      @include media-breakpoint-down(sm) {
        @include font-size(40);
      }
    }

    @if $label == title-3 {
      @include media-breakpoint-down(sm) {
        @include font-size(28);
      }
    }

  }
}