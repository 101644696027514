#header {
	background-color: rgba(255,255,255, .4);
	position: absolute;
	top: 0;
	padding: 20px 0;
	width: 100%;
	z-index: 10;
	@include transition(all, 0.2s, ease-in-out);

	@include media-breakpoint-down(sm) {
		padding: 50px 0 10px 0;
	}

	&:after  {
		content:"";
		background: linear-gradient(to bottom,  rgba(255,255,255,.5) 0%,rgba(255,255,255,0) 100%);
		height: 200px;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
		@include media-breakpoint-down(sm) {
			height: 100px;
		}
	}


	.logo {
		display: block;
		max-width: 327px;
		transition: all 0.1s ease-in-out;

		&:hover {
			transform: scale(.95);
		}

		@include media-breakpoint-down(lg) {
			max-width: 200px;
		}

		@include media-breakpoint-down(sm) {
			max-width: 150px;
		}


	}

	.title {
		color: map.get($colors, 'green');
		text-transform: uppercase;
		font-family: $font-regular;
		position: relative;
		z-index: 2;
		@include font-size(42);
		@include media-breakpoint-down(lg) {
			top: 45px;
			color: $white;
			@include font-size(36);
		}
		@include media-breakpoint-down(sm) {
			top: 38px;
			color: $white;
			@include font-size(28);
		}
	}

	.logo,
	#button--mobile .text,
	.header-top {
		position: relative;
		z-index: 3;
	}
}

.button-clock {
  align-items: center;
  color: map.get($colors, 'blue');
  display: flex;
  font-family: $font-regular;
  padding-left: 15px;
  transition: all .2s ease-in-out ;
  position: relative;
  z-index: 3;
  @include font-size(14);

  > * {
    transition: all .2s ease-in-out ;
    &:hover {
      color: map.get($colors, 'green');
    }
  }

  @include media-breakpoint-down(md) {

  }

  @include media-breakpoint-down(sm) {
    display: none
  }
}


.button-phone {
	align-items: center;
	color: map.get($colors, 'blue');
	display: flex;
	justify-content: flex-end;
	font-family: $font-regular;
	padding-left: 15px;
	transition: all .2s ease-in-out ;
	position: relative;
	z-index: 3;
	@include font-size(30);

  > * {
    transition: all .2s ease-in-out ;
    &:hover {
      color: map.get($colors, 'green');
    }
  }

  @include media-breakpoint-down(md) {
	@include font-size(24);
  }

  @include media-breakpoint-down(sm) {
	position: absolute;
	top: -50px;
	justify-content: center;
	width: 100%;
	left: 0;
	background-color:  map.get($colors, 'blue');
	color: $white;
	padding: 5px 0;
	@include font-size(20);
  }

  .light {
    font-family: $font-regular;
  }

}

.menu-open {
	#button--mobile {
		.button--mobile span {
			background-color: $white;
		}
	}
}

#button--mobile {
	position: absolute;
	top: 47px;
	text-align: center;
	right: 20px;
	align-items: center;
	color: map.get($colors,'black');
	text-transform: uppercase;
	font-family: $font-regular;
	@include font-size(16);
	@include media-breakpoint-down(lg) {
		top: 15px;
	}
	@include media-breakpoint-down(sm) {
		top: 6px;
	}

}

body {
	&.menu-open {
		.header-top {
			padding-bottom: 0;
		}
	}
}

#navigation-overlay {
  background-color: map.get($colors,'blue');
  height: 0;
  left: 0;
  position: fixed;
  overflow-x: hidden;
  bottom: 0;
  transition: all 0.1s ease-in-out;
  width: 100%;
  z-index: 11;

  .overlay-content {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    height: 100%;
    position: relative;
    text-align: center;
    top: 0;
	padding: 0 20px;
    width: 100%;

    .link {
      color: rgba(255, 255, 255, 0.5);
      display: block;
      font-size: 6vh;
	  line-height: 1.3em;
	  margin: 10px 0;
      text-decoration: none;
      transition: 0.3s;
	  @include media-breakpoint-down(sm) {
		font-size: 4.5vh;
	}

      &:hover,
      &:focus,
	  &.active {
       color: map.get($colors, 'white');
      }
    }
  }
}

#header-page {
	background-image: url('assets/images/resa-cover-bg.jpg');
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	padding-top: 220px;
	padding-bottom: 70px;
	min-height: 275px;
	text-align: center;
	color: $white;
	background-position: 0 -50px;
	@include media-breakpoint-down(lg) {
		padding-top: 160px;
		padding-bottom: 50px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 150px;
		padding-bottom: 0px;
	}
	.title-1 {
		position: relative;
		text-shadow: 0 3px 5px rgba(0,0,0,.4);
	}
	@include media-breakpoint-down(xl) {
		background-size:cover;
		background-position: center;
	}

}
