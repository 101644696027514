.resa-picker {
  .datepicker-wp,
  .input-wp {
    border: none;
    background: $white !important;
    border-radius: 6px;
    display: block;
    height: 40px;
    outline: none !important;
    padding: 0 15px;
    width: 100%;
    font-weight: 500;
    @include font-size(13);
    
    input {
      background: transparent !important;
      color: map.get($colors,"black") !important;
      border: 0;
      height: 100%;
      outline: none !important;
      width: 100%;

      &::placeholder {
        color: map.get($colors,"black") !important;
      }
    }

    .ui-datepicker-trigger {
      margin-top: -12px;
      right: 11px;
    }
  }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #0000 #0000 map.get($colors,'green');
}
  .select2-container.select2-container--default .select2-selection--single {
    background: $white !important;
    border: 0;
    border-radius: 6px !important;
    .select2-selection__arrow {
      right: 10px;
      b {
        border-top-color: map.get($colors,'green') !important;
      }
    }
    .select2-selection__arrow b {
      border-top-color: $white;
    }
    .select2-selection__rendered,
    .select2-selection__placeholder {
      color: map.get($colors,"black");
      @include font-size(13);
    }
  }

  .select-hour  + .select2-container {
    .select2-selection__arrow {
      @extend .sprite;
      @extend .icon-clock;
      right: 15px;
      top: 6px !important;

      b {
        display: none;
      }
    }
  }
}

.reservation-border-left {
  position: relative;
  &:after {
    bottom: 0;
    left: 20px;
    content: "";
    display: block;
    background-color: map.get($colors, 'white');
    height: 100%;
    width: 1px;
    position: absolute;
  }
  @include media-breakpoint-down(xl) {
    &:after {
      left: 10px;
    }
  }

  @include media-breakpoint-down(lg) {
    &:after {
      display: none;
    }
  }
}

.form-resa {
  border-radius: 15px;
  overflow: hidden;
  background-color: rgba(255,255,255, .6);
  
  @include media-breakpoint-down(sm) {
    border-radius: 0;
    width: calc(100% + 45px);
    padding-left: 15px;
    margin-left: -30px;
  }
 

  .row {
    position: relative;
  }

  .label-row {
    display: inline;
    left: 0;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 1;
    color: map.get($colors, 'black');
    text-align: left;
    width: auto;

    &.alt {
      left: 15px;

      @include media-breakpoint-down(lg) {
        margin-top: -20px;
        .text-nowrap { 
          width: 70px;
          white-space: normal !important;
        }
      }
      @include media-breakpoint-down(sm) {
        margin-top: 0;
        left: 0;
        top: auto;
        .text-nowrap { 
          width: 100%;
        }
      }
    }
  }
  
  @include media-breakpoint-down(md) {
    .label-row {
      top: 20px;
    }
  }  

  @include media-breakpoint-down(sm) {
    
    .label-row {
      left: auto;
      margin-bottom: 10px;
      position: relative;
      top: auto;
    }
  }    
}

.reservation-wrapper {
  background-image: url('assets/images/resa-cover-bg.jpg');
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  padding-top: 150px;
  position: relative;
  min-height: 900px;
  &:after {
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom,  rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 99%,rgba(0,0,0,0) 100%);
    height: 50%;
  }

  @include media-breakpoint-down(lg) {
    padding-top: 75px;
    min-height: 8cap;
  }

  @include media-breakpoint-down(md) {
    padding-top: 120px;
  }

  .skills-wrapper {
    background-color: rgba(0, 162, 14, 0.8);
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    padding: 30px 0;

    @include media-breakpoint-down(lg) {
      position: relative;
      bottom: auto;
      left: auto;
    }



  }

  .form-resa {
    margin-top: 50px;
    position: relative;
    z-index: 9;
  }


  @include media-breakpoint-down(md) {
    background-attachment :inherit;
    background-size: cover;
  }
  
  .container {
    &:not(.except){
      padding: 150px 90px 70px;
      position: relative;
      z-index: 2;
    }
  }

  @include media-breakpoint-down(xxl) {
    .container {
      &:not(.except){
        display: block;
        padding: 150px 50px 70px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .form-resa {
      margin-top: 30px;
    }
    .container {
      &:not(.except){
        padding: 80px 0;
      }
    }
    .car-decoration {
      display: none;
    }
  }  

  @include media-breakpoint-down(md) {
    .container {
      &:not(.except){
        padding: 50px 0;
      }
    }
  }  

  @include media-breakpoint-down(sm) {
    .container {
      &:not(.except){
        padding: 20px 15px 0 20px;
      }
    }
    .form-resa {
      margin-top: 20px;
    }
  }  
}

.form-resa-inner-page {
  position: relative;
  margin-top: 50px;
  padding: 0 30px;

  &.except-reservation {
    margin-top: 0;
    background: map.get($colors,'grey');

    .form-resa {
      background-color: transparent;

      .ph-sm-50 {
        padding-left: 0;
        padding-right: 0;
      }
      @include media-breakpoint-down(sm) {
        .ph-sm-50 {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 0;
    margin-top: 30px;
  }
  .form-resa {
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(sm) {
      width: calc(100% + 52px);
      padding-right: 10px;
    }
  }
  .reservation-wrapper {
    background: none !important;
  }

}

