$sprites : (
  (scroll-top, 40px, 40px, 0 0),
  (arrow-bottom-blue, 20px, 10px, -410px 0),
  (arrow-top-blue, 20px, 10px, -410px -10px),
  (arrow-left-blue, 20px, 20px, -450px 0),
  (arrow-right-blue, 20px, 20px, -430px 0),
  (arrow-left-white, 20px, 20px, -450px 0),
  (arrow-right-white, 10px, 10px, -400px 0),
  (arrow-right-blue-small, 10px, 10px, -390px 0),
  (icon-facebook, 30px, 30px, -470px 0),
  (icon-whatsapp, 30px, 30px, -500px 0),
  (icon-calendar, 20px, 20px, -140px 0),
  (icon-clock, 30px, 30px, -210px 0),
  (icon-phone, 30px, 30px, -50px 0),
  (icon-skill-1, 30px, 30px, -270px 0),
  (icon-skill-2, 30px, 30px, -300px 0),
  (icon-skill-3, 30px, 30px, -330px 0),
  (icon-skill-4, 30px, 30px, -360px 0),
  (icon-stars, 130px, 30px, -80px 0px),

  (vehicle-clim, 30px, 30px, 0 -40px),
  (vehicle-radio, 30px, 30px, -30px -40px),
  (vehicle-passager-2, 30px, 30px, -60px -40px),
  (vehicle-passager-3, 30px, 30px, -90px -40px),
  (vehicle-passager-4, 30px, 30px, -120px -40px),
  (vehicle-passager-5, 30px, 30px, -150px -40px),
  (vehicle-passager-6, 30px, 30px, -180px -40px),
  (vehicle-passager-7, 30px, 30px, -210px -40px),
  (vehicle-passager-8, 30px, 30px, -240px -40px),
  (vehicle-passager-9, 30px, 30px, -270px -40px),
  (vehicle-porte-2, 30px, 30px, -300px -40px),
  (vehicle-porte-3, 30px, 30px, -330px -40px),
  (vehicle-porte-4, 30px, 30px, -360px -40px),
  (vehicle-porte-5, 30px, 30px, -390px -40px),
  (vehicle-boite-m, 30px, 30px, -420px -40px),
  (vehicle-boite-a, 30px, 30px, -450px -40px),
  (vehicle-motorisation-d, 30px, 30px, -480px -40px),
  (vehicle-motorisation-e, 30px, 30px, -510px -40px),
  (vehicle-motorisation-m, 30px, 30px, -540px -40px),
  (vehicle-bagage, 30px, 30px, -570px -40px),
  (vehicle-puissance, 30px, 30px, -600px -40px),
);

.sprite {
	background-image: url('assets/icons/sprite.png');
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: text-top;

  @each $label, $width, $height, $background-position in $sprites {
    &.#{$label} {
      width: $width;
      height: $height;
      background-position: $background-position;
    }
  }
}

.icon-clock {
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-position: -210px 0;
}
